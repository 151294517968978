.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#order-nav-dropdown, #show-nav-dropdown, #menu-nav-dropdown {
  color: #AEB6BF;
}

.nav-text {
  color: #AEB6BF;
}

.imglink {
  cursor: pointer;
}

.navBarLink { 
  color: inherit;
} 

.navBarLink:hover {
  color: inherit;
}

a {
  text-decoration: inherit;
}

.notification-item {
  height: 32px;
}

.ebedded-icon {
  opacity: 0.5;
}

.ebedded-icon:hover {
  opacity: 0.75;
}

.events-container {
  z-index: 2;
  position: fixed;
  right: 0px;
  max-width: 300px;
  max-height: 400px;
  transition: margin .5s;
  margin-top: -408px;
  background-color: #343a40 !important;
}

.events-container.is-open {
  margin-top: 8px;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 56px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

.content {
  padding-top: 16px;
}

.form-button-col {
  padding-left: 5px;
  padding-top: 5px;
}

.form-input-col {
  padding-left: 0;
  padding-right: 0;
  padding-top: 5px;
}

.scrollable-dropdown-menu {
  max-height: 350px;
  overflow-y: scroll;
}

.mod {
  position: relative;
  overflow: hidden;
}

.mod:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 0;
  border-color: transparent #0d6efd transparent transparent;
  right: 0;
  top: 0;
  position: absolute;
}

.bool-col {
  text-align:center;
}

.closeSymbol::after{
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
  opacity: 0.6;
}

.closeSymbol:hover::after {
  opacity: 1;
}

.categoryNavigatorContainer {
  overflow-x: scroll;
  white-space: nowrap;
  height: 350px;
  border: solid;
  border-color: lightgray;
}

.categoryNavigatorItemList {
  height: 100%;
  width: 300px;
  border-right: solid;
  border-color: lightgray;
  display: inline-block;
  overflow-y: auto;
  margin-left: 5px;
}

.categoryNavigatorSelectedItem {
  background-color: lightgray;
}

.categoryNavigatorUnselectedItem {
}

.categoryNavigatorTagsContainer {
  padding: 10px;
}

.categoryNavigatorTaggedItem {
  border: solid;
  border-color: #00BD67 ;
  background-color: #BDFFE1;
  border-radius: 7px;
  display: inline-block;
  padding: 5px;
  margin: 5px;
}

.categoryNavigatorTagIcon {
  color: #4E4E4E;
}

.categoryNavigatorTagIcon:hover {
  color: #9A9A9A;
  cursor: pointer;
}

#viewer-selector-dropdown {
  padding: 0px;
}

.favitem {
  color: inherit;
}

.favitem:hover {
  color: gray;
  cursor: pointer;
}

.unfavitem {
  color: gray;
}

.unfavitem:hover {
  color: inherit;
  cursor: pointer;
}

.terminalTitle {
  padding-left: 5px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 2px;
}

.terminalBody {
  padding: 2px;
}

#nav-dropdown-login {
  color: #dee2e6;
}

.componentOverlay {
  width: 0px;
  height: 0px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  background: lightgrey;
  opacity: 60%;
  display: none;
}
